<template>
 <sdDrawer
  ref="timeBockAddRef"
  :form="form"
  btnText="Toevoegen"
  submitBtnText="Opslaan"
  title="Tijdblok aanmaken"
  type="submit"
 >
  <FormValidationWrap>
   <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" @finish="CreateArea">
    <a-row :gutter="16">
     <a-col :span="12">
      <a-form-item label="Datum" name="date">
       <DatePickerWrapper>
        <a-date-picker v-model:value="form.date" :format="dateFormat" class="w-100"/>
       </DatePickerWrapper>
      </a-form-item>
     </a-col>
     <a-col :span="12">
      <a-form-item label="Tijdstip van" name="timeFrom">
       <a-time-picker v-model:value="form.timeFrom" class="w-100" format="HH:mm" value-format="HH:mm"/>
      </a-form-item>
     </a-col>
     <a-col :span="12">
      <a-form-item label="Tijdstip toll" name="timeTill">
       <a-time-picker v-model:value="form.timeTill" class="w-100" format="HH:mm" value-format="HH:mm"/>
      </a-form-item>
     </a-col>
     <a-col :span="12">
      <a-form-item label="Maximale aantal afspraken" name="maxAppointments">
       <a-input-number v-model:value="form.maxAppointments" class="w-100" placeholder=""/>
      </a-form-item>
     </a-col>
    </a-row>
    <div
     :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
    >
     <a-button :disabled="loadingCreateArea" :loading="loadingCreateArea" class="btn-signin" html-type="submit"
               size="large"
               style="color:white !important;" type="primary"> Opslaan
     </a-button>
    </div>
   </a-form>
  </FormValidationWrap>
 </sdDrawer>
</template>
<script>
import {computed, defineComponent, reactive, ref} from 'vue';
import {useStore} from "vuex";
import {DatePickerWrapper, FormValidationWrap} from "../../styled";
import {useRoute} from "vue-router";

const TimeBlockAdd = defineComponent({
 name: 'TimeBlockAdd',
 components: {
  FormValidationWrap,
  DatePickerWrapper
 },
 data() {
  return {
   dateFormat: 'DD-MM-YYYY',
  }
 },
 setup() {
  const timeBockAddRef = ref();
  const {params} = useRoute();
  const formRef = ref();
  const {state, dispatch} = useStore();
  const loadingCreateArea = computed(() => state.lead.loadingAreaCreate);
  const form = reactive({
   leadAreaID: params.id,
   date: '',
   maxAppointments: '',
   active: true,
   timeFrom: '',
   timeTill: '',
  });
  const rules = {
   date: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   maxAppointments: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   timeFrom: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   timeTill: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };
  const closeDrawer = () => {
   timeBockAddRef.value.onClose();
   formRef.value.resetFields();
   dispatch('getAreas')
  }
  const CreateArea = async () => {
   await dispatch('createTimeBlock', {value: form, close: closeDrawer});
  };


  return {
   form,
   rules,
   CreateArea,
   loadingCreateArea,
   timeBockAddRef,
   formRef,
   closeDrawer,
  };
 },
});

export default TimeBlockAdd;
</script>
